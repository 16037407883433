@use "sass:color";

@use "bootstrap" as bs;
@use "app";
@use "sass:map";

.search-result-card {
  min-height: 100px;
  font-size: 12px;

  @media (min-width: 768px) {
    min-height: 175px;
  }
}

@media (min-width: 768px) {
  #filters-section.d-md-block {
    display: block !important;
  }
}

a.clickable-search-term {
  color: unset;
  text-decoration-line: underline;
  text-decoration-color: color.adjust(bs.$secondary, $lightness: -40%);
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 20px;
  }
}

mark {
  color: unset;
  background-color: rgba(darken(bs.$secondary, 5%), 0.7);
  padding: 0 0.08em;
}

.ais-SearchBox-loadingIndicator {
  position: absolute;
  top: 21px;
  right: 60px;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.2rem + 1.2vw);
  }

  h4,
  .h4 {
    font-size: calc(1rem + 0.12vw);
  }
}

.searchbox-input:focus {
  outline: none;
  box-shadow: none;
  border-color: white;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.text-muted {
  color: bs.$text-muted;
}
@media (min-width: map.get(bs.$container-max-widths,"md")) {
  #sort-by {
    width: max(20%, 100px);
  }
}
